import React, { Component } from 'react';
import PropTypes from "prop-types";
import Slider from 'react-slick';
import Img from "gatsby-image";

// import Image from '../Image';
import { ThemeContext } from '../../context/ThemeProvider';
import { stripHtml } from '../../utilities/helpers';
import '../../styles/carousel.scss';

export class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slideIndex: 0
        }
    }

    render() {
        const { sliderInterval, slides, backgroundColor, sectionIdentifier, mediaQueries } = this.props;
        const customSettings = {};

        if (backgroundColor) {
            customSettings.style = {
                backgroundColor: backgroundColor
            };
        }

        if (slides.length === 1) {
            const { title, subtitle, description, media, mediaAlignment } = slides[0];
            let path = media.url.split('/');
            let mediaQuery = mediaQueries.find(x => x.fluid.originalName === `${path[path.length - 1]}`);
            const carouselContentSettings = {};

            if (sectionIdentifier) {
                carouselContentSettings.id = sectionIdentifier;
            }
            
            return (
                <div {...customSettings} className="carousel-container">
                    <div className="carousel-item" key={`carousel__${path[path.length - 2]}`}>
                        <div className={`layout${mediaAlignment === "Left" ? '-row': ' layout-row-reverse'} layout--centered`}>
                            <div className="flex-2-cols carousel-item--image">
                                {/* <Image variant="Original" src={media.url} alt={stripHtml(title)} /> */}
                                <Img fluid={mediaQuery?.fluid} alt={stripHtml(title)} style={{ maxHeight: 1080 }} className="img" />
                            </div>
                            <div className="layout-column flex-2-cols carousel-item--content">
                                <div {...carouselContentSettings} className="element-hash-view"></div>
                                <div className="carousel-item--content__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></div>
                                <div className="carousel-item--content__title" dangerouslySetInnerHTML={{ __html: title }}></div>
                                <div className="carousel-item--content__description" dangerouslySetInnerHTML={{ __html: description }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        let sliderSettings = {
            dots: false,
            arrows: false,
            fade: true,
            infinite: true
        }

        if (!!parseInt(sliderInterval)) {
            // sliderSettings.speed = sliderInterval * 1000;
            sliderSettings.autoplay = true;
            sliderSettings.autoplaySpeed = sliderInterval * 1000;
        }

        return (
            <div {...customSettings} className="carousel-container">
                <Slider ref={slider => (this.slider = slider)} {...sliderSettings} beforeChange={(current, next) => this.setState({ slideIndex: next }) }>
                    {
                        slides.map(({ title, subtitle, description, media, mediaAlignment }, index) => {
                            let path = media.url.split('/');
                            let mediaQuery = mediaQueries.find(x => x.fluid.originalName === `${path[path.length - 1]}`);
                            const carouselContentSettings = {};

                            if (index === 0) {
                                if (sectionIdentifier) {
                                    carouselContentSettings.id = sectionIdentifier;
                                }
                            }

                            return (
                                <div className="carousel-item" key={`carousel__${path[path.length - 2]}`}>
                                    <div className={`layout${mediaAlignment === "Left" ? '-row': ' layout-row-reverse'} layout--centered`}>
                                        <div className="flex-2-cols carousel-item--image">
                                            {/* <Image variant="Original" src={media.url} alt={stripHtml(title)} /> */}
                                            <Img fluid={mediaQuery?.fluid} alt={stripHtml(title)} style={{ maxHeight: 1080 }} className="img" />
                                        </div>
                                        <div className="layout-column flex-2-cols carousel-item--content">
                                            <div {...carouselContentSettings} className="element-hash-view"></div>
                                            <div className="carousel-item--content__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></div>
                                            <div className="carousel-item--content__title" dangerouslySetInnerHTML={{ __html: title }}></div>
                                            <div className="carousel-item--content__description" dangerouslySetInnerHTML={{ __html: description }}></div>
                                            <div className="carousel-dots">
                                                <ul>
                                                    {
                                                        slides.map((slide, index) => 
                                                            <li key={`carousel__dots__${index}_${path[path.length - 2]}`} className={`clickable ${index} ${this.state.slideIndex === index ? 'carousel-dots--active' : ''}`} onClick={e => this.slider.slickGoTo(index)}></li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        )
    }
}

Carousel.contextType = ThemeContext;

Carousel.propTypes = {
    sliderInterval: PropTypes.number,
    slides: PropTypes.arrayOf(PropTypes.object)
}

export default Carousel;