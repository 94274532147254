import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeProvider';
import { css } from 'glamor';

export default function Content({ content, backgroundColor, sectionIdentifier, topPadding, bottomPadding }) {    
    const customSettings = {};
    const { description } = useContext(ThemeContext);
    const customStyle = css({
        padding: `${topPadding ? topPadding : '75px'} 0 ${bottomPadding ? bottomPadding : '75px'} 0`,
        '@media (max-width: 786px)': {
            padding: `10px 50px`
        },
        '@media (max-width: 576px)': {
            padding: `10px 15px`
        }
    });

    if (backgroundColor) {
        customSettings.style = {
            backgroundColor: backgroundColor
        };
    }

    if (sectionIdentifier) {
        customSettings.id = sectionIdentifier;
    }

    if (content.includes("/media/")) {
        const regex = /\/media\//gi;
        content = content.replace(regex, "/static/media/");
    }

    return (
        <div {...customSettings} className={`listing-content-container ${customStyle}`}>
            <div className={`listing-content__contents ${description}`} dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    )
}
