import { stripHtml } from './helpers';

class SchemaGenerator {
    generateHotelSchema(data) {
        let context = {};
        context["@context"] = "http://schema.org";
        context["@type"] = ["HotelRoom","Product"];
        context.name = data.title;
        context.url = data.url;
        
        if (data.publicDescription.length > 0) {
            context.description = stripHtml(data.publicDescription[0].summary);
        }

        context.telephone = "+4550171700";
        context.email = "reservations@dinesencollection.com";
        if (data.checkinTime) {
            context.checkinTime = data.checkinTime;
        }

        if (data.checkoutTime) {
            context.checkoutTime = data.checkoutTime;
        }
        
        if (data.address.length > 0) {
            context.address = {
                "@type": "PostalAddress",
                streetAddress: data.address[0].street,
                addressLocality: data.address[0].city,
                postalCode: data.address[0].zipCode,
                addressCountry: data.address[0].country
            }

            context.geo = {
                "@type": "GeoCoordinates",
                latitude: data.address[0].lat,
                longitude: data.address[0].lng
            }
            context.hasMap = `https://www.google.com/maps/place/${data.address[0].lat},${data.address[0].lng}`
        }

        if (data.amenities) {
            context.amenityFeature = [];
            data.amenities.forEach(amenity => {
                context.amenityFeature.push({
                    "@type": "LocationFeatureSpecification",
                    name: amenity.amenityName
                });
            });
        }

        context.makesOffer = {
            "@type": "Offer",
            priceCurrency: data.prices != null && data.prices.length > 0 ? data.prices[0].currency : "DKK"
        };

        context.makesOffer.acceptedPaymentMethod = [];
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#Cash" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#AmericanExpress" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#DinersClub" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#Discover" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#JCB" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#MasterCard" });
        context.makesOffer.acceptedPaymentMethod.push({ "@type": "PaymentMethod", url: "http://purl.org/goodrelations/v1#VISA" });


        if (data.airbnbReviewAverageRating && data.airbnbTotalReviewsCount) {
            context.aggregateRating = {};
            context.aggregateRating["@type"] = "AggregateRating";
            context.aggregateRating.ratingValue = data.airbnbReviewAverageRating;
            context.aggregateRating.ratingCount = data.airbnbTotalReviewsCount;
            context.aggregateRating.reviewCount = data.airbnbTotalReviewsCount;
        }

        if(data.imageListing && data.imageListing.length > 0){
            context.image = `https://www.dinesencollection.com${data.imageListing[0]}`;
        }
        
        context.brand = "Dinesen Collection";

        return JSON.stringify(context);
    }

    generateFAQSchema(data) {
        let context = {};
        context["@context"] = "http://schema.org";
        context["@type"] = "FAQPage";
        context.mainEntity = [];
        data.forEach(d => {
            context.mainEntity.push({
                "@type": "Question",
                name: d.title,
                acceptedAnswer: {
                    "@type": "Answer",
                    text: stripHtml(d.description)
                }
            });
        })

        return JSON.stringify(context);
    }

    generateVideoSchema () {

    }

    generateAggregateRatingSchema(data) {
        if (!data.airbnbReviewAverageRating && !data.airbnbTotalReviewsCount)
            return "";

        let context = {};
        context["@context"] = "http://schema.org";
        context["@type"] = "Product";
        context.name = data.title;
        context.aggregateRating = {};
        context.aggregateRating["@type"] = "AggregateRating";
        context.aggregateRating.ratingValue = data.airbnbReviewAverageRating;
        context.aggregateRating.ratingCount = data.airbnbTotalReviewsCount;
        context.aggregateRating.reviewCount = data.airbnbTotalReviewsCount;

        return JSON.stringify(context);
    }
}

const schemaGenerator = new SchemaGenerator();
export default schemaGenerator;