import React from 'react'
import PropTypes from "prop-types";

import Img from "gatsby-image";
import { isVideo } from '../../utilities/helpers';

export default function MediaBanner({ title, subtitle, media, mediaQuery }) {
    let isMediaVideo = isVideo(media.url);
    let className = '';
    
    switch (media.variant) {
        case "Full width":
            className = "img-full-width";
            break;
        case "Normal":
            className = "img-normal";
            break;
        default:
            className = "img-normal";
            break;
    }

    let mediaProps = {};

    if (!isMediaVideo) {
        if (media.variant === 'Full width') {
            //let imgPath = media.url.split("/");
            // mediaProps = {
            //     fluid: mediaQuery.fluid,
            //     alt: title || '',
            //     style: {
            //         minHeight: `65vh`,
            //         maxHeight: 1080
            //     },
            //     imgStyle: {
            //         objectFit: 'cover'
            //     }
            // }
            
            return (
                <div className={`media-section ${className}`}>
                    <div className='img-full-w blurred-img' style={{
                        backgroundImage: `url(${mediaQuery.fluid.base64})`,
                        backgroundPosition: 'bottom',
                        position: 'absolute'
                    }}>
                        <img loading='lazy' src={mediaQuery.fluid.src} alt={title} />
                    </div>
                </div>
            )
        }
        else {

            mediaProps = {
                fluid: mediaQuery.fluid,
                alt: title || '',
                style: {
                    maxWidth: mediaQuery.fluid.presentationWidth,
                    margin: `0 auto`
                }
            }
        }
    }

    return (
        <div className={`media-section ${className}`}>
            {
                !isMediaVideo ? <Img {...mediaProps} />
                : <video src={`/static${media.url}`} loop muted autoplay playsinline></video>
            }
        </div>
    )
}

MediaBanner.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    media: PropTypes.object,
}